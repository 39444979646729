const Eng = {
	welcomeMsg: "Welcome to Your App",
	// Menu left -- sidebar
	LeadSources: "Lead Sources",
	MySources: "My sources",
	NewSources: "New source",
	LeadManagement: "Lead Management",
	MyLeads: "Leads",
	Qualification: "Qualification",
	Analytics: "Analytics",
	Settings: "Settings",
	Workspace: "Workspace",
	LeadStatus: "Lead status",
	Members: "Members",

	// Menu top -- navbar
	CurrentlyManaging: "Currently managing",
	PopoverChangeWorkSpace:
	"You can manage multiple workspaces with a total separation of leads and access rights, So make sure to choose the right workspace before proceeding to manage your leads.",
	Options: "Options",
	NewWorkspace: "New Workspace",
	YouHave: "You have",
	Leads: "Leads",
	PlanInUse: "Plan in use",
	ProfileSettings: "Profile Settings",
	WorkspaceSettings: "Workspace Settings",
	HelpCenter: "Help Center",
	Logout: "Logout",
	Upgrade: "Upgrade",
	AddWorkspace: "Add Workspace",
	TypeYourWorkspaceName: "Type Your Workspace Name",
	Timezone: "Timezone",
	TheTimezoneToBeUsedInThisWorkspace:
	"You can set a custom time zone for your workspace.",
	Country: "Country",
	CountryDesc:
	"Country field will help us determine the phone prefix, that will be used in all your calls.",
	UpgradeDesc:
	"We’re sorry, but this feature is not available on your current plan",
	UpgradeButton: "UPGRADE NOW",
	WelcomeToOhmylead: "Welcome to Ohmylead!",
	OptimizeTitle:
	"For the best optimized experience, we recommend using Ohmylead on your computer. Please note that our mobile application will be available SOON.",
	Ok: "Ok",
	YourAccount:'Account',

	// Layout Global component
	CheckEmailLink:
	"Please check your email and click on the activation link. If you did not receive the email :",
	RequestAnother: "Click here to request another",
	TrialingAgencyPlan:
	"You are now trialing Agency plan. You have {TrialPeriod} days to try out our premium features or",
	UpgradeNow: "upgrade now",
	DefaultMonthPrice: "for just $9 /month.",

	// Analytics
	analytics: "Analytics",
	AllTime: "All Time",
	Leadsbystatus: "Leads by status",
	Leadsbysources: "Leads by sources",
	Nodatayet: "No data yet",
	Unfortunately:
	"Unfortunately, we have not computed any data because you don’t have any lead",
	TitleNoteLink: "You can try to ",
	AddAnotherSource: "add another source",
	YourLeadsFunnel: "Your Leads Funnel",
	// My source
	Sync: "Sync",
	SyncTitle: "your lead source and let's open the floodgates together !",
	VisitPage: "Visit Page",
	Publishedon: "Published on",
	WelcomeEmail: "Welcome Email",
	LeadsAlert: "Leads Alert",
	Mapping: "Mapping",
	DeleteSource: "Delete Source",
	NoSource: "No source to display !",
	NoFbCampaign: "No FB Campaign to display !",
	DeletingConfirg:
	"By deleting <b>{name}</b>, all leads inside will also be deleted, Are you sure you want to continue ?",
	YesDeleteIt: "Yes Delete It",
	// New source
	ConnectFbTitle: "Connect your Facebook Lead Ads account",
	ConnectFbDesc:
	"Simplify your work with those forms by giving you automatically all your results and letting you the freedom to change your mapping directly in our solution.",
	Next: "Next",
	SelectLeadTitle: "Add Your Lead Ad Form",
	SelectLeadDesc:
	"To add a lead ad form, you need to choose it's associated Page",
	PleaseWait: "Please wait...",
	Previous: "Previous",
	NameYourSource: "Name your source:",
	NameSourceTitle: "Please provide a Name Source.",
	FieldsMapping: "Fields Mapping",
	ImportExistingLeads: "Import existing leads",
	LeadAlert: "Lead Alert",
	Finish: "Finish",
	ExcelDesc:
	"Start in few seconds to qualify your leads by adding any contact list. Warning, your spreadsheets must follow a few requirements in order to have a good match with the Ohmylead system.",
	ExcelTitle:
	"Click on the icon bellow to choose your excel file from this computer",
	ImportExcelFile: "Import Excel File",
	MatchTitle: "Match the fields between the system",
	OptionSelect: "Select a Field (Ignore)",
	WelcomeEmailTabs:
	"This is the mail that the new client will receive. You can write a specific message or just choose one of our templates",
	SenderEmail: "Sender email",
	SenderName: "Sender name",
	Replytoemail: "Reply to email",
	Replytoname: "Reply to name",
	Message: "Message",
	SelectAField: "Select a Field",
	LeadAlertTitle:
	"Whenever and wherever a new lead is converted, you get notified in real time",

	login_title: "Some leads need to be won",
	Email: "Email",
	TypeEmail: "Type the email address",
	Password: "Password",
	login_validate: "These credentials do not match our records.",
	login_account: "Login to your account",
	login_accountcheck: "Don't have an account?",
	ForgotPassword: "Forgot password ?",
	DeleteWorkspace: "Delete workspace",
	LeaveWorkspace: "LeaveWorkspace",
	Yes: "Yes",
	ConfrmDelete: "Do you really want to {TypeAction} this workspace ?",

	FullName: "Full name",
	EmailAddress: "Email address",
	PhoneNumber: "Phone number",
	TimezoneDesc: "The timezone to be used in all the application.",
	ChangePassword: "Change password",
	PasswordDesc: "Type your current password to save changes",

	Showing: "Showing",
	leads: "leads",
	YouHaveSelected: "You have selected",
	BulkActions: "Bulk actions",
	Customize: "Customize",
	ShowHideColumns: "Show/Hide columns",
	ExportCsvFile: "Export csv file",
	SyncLeads: "your lead source and let's open the floodgates together !",
	Source: "Source",
	Createdon: "Createdon",
	FirstName: "First Name",
	LastName: "Last Name",
	Phone: "Phone",
	AddField: "Add field",
	MinusField: "Minus field",
	Delete: "Delete",
	activities: "Activities",
	AddNote: "Add note",
	AddNewLead: "Add New Lead",

	YouWantToDelete: "You want to delete :",
	lead: "lead",
	YesDelete: "Yes, delete",
	ConfirmDelete: "Do you really want to delete",
	NewLeadReceived: "New lead received.",

	// New ------------------------------------
	//DemoDataDesc:
	//"This is only demo data to see how ohmylead.com qualify your leads",
	//ClearDemoData: "Click here to Clear Demo Data",
	DemoDataDesc:
	"Demo leads were added to your account.",
	ClearDemoData: "Click here to clear Demo Leads",

	Qualify: "Qualify",
	Unqualify: "Unqualify",
	Archive: "Archive",

	BeingVerified: "Being Verified",
	NotValid: "Not Valid",
	Valid: "Valid",
	at: "at",
	From: "From",
	to: "to",
	PleaseFillOutThisField: "Please fill out this field.",
	Status: "Status",
	AddLead: "Add Lead",
	Close: "Close",
	DisplayedColumns: "Displayed columns",
	Done: "Done",
	VerificationIsComplete: "Verification is complete",
	HideResults: "Hide results",
	OfEmailsAreValid: "Of emails are valid",
	OfPhonesAreValid: "Of phones are valid",
	OfLeadsAreDuplicate: "Of leads are duplicate",
	YouSeeHowEasyIts: "You see how easy it is",
	YourTurnNow: "Your turn now",
	SyncYourFirstLeadSource: "Sync your first lead source",
	ShowResults: "Show results",
	Back: "Back",
	DemoDataTopTitle:
	'Choose between multiple integrations to sync your leads.',
	DemoDataTitle:
	"You can start with our demo to see how easy to manage your lead with simple click",
	DemoDataStart: "Start with demo data",
	DemoDataReceive:
	"Receive new leads from {title} source in your Ohmylead account",
	Configure: "Configure",

	Token: "Token",
	Login: "Login",
	title_forget: "Please check your email to reset your password",
	BackToHome: "Back to Home",
	ForgotYourPassword: "Forgot your password?",
	ForgotPasswordTitle: "No problem, so easy to recover it",
	BackToLogin: "Back to login",
	CheckRegister: "Don’t have an account?",
	TypeYourNewPassword: "Type your new password",
	Reset: "Reset",
	StartFreeTitle: "Start with your free account today.",
	FirstLastName: "First & last name",
	Hide: "Hide",
	Show: "Show",
	AlreadyHaveAccount: "Already have an account ?",

	// New & -------------------------------------------------------------

	Connect: "Connect",
	WhiteLabel: "White Label",
	StartNow: "Start Now",
	LastStepDescription:
	"Important notice before adding your custom subdomain! In order for your custom subdomain to link up correctly with your Ohmylead account, please login to your domain registrar DNS Zone, and add an <b>A record</b> that points you subdomain (or www if you’re linking a domain name) to <b>161.35.80.116</b> We recommend adding the DNS record before validating your domain here. If you’re not sure how to do that, please get in touch with us, we’ll be glad to assist you.",

	OnlyAgencyPlan: "Only Agency Plan",
	CompanyInformation: "Company Information",
	CompanyName: "Company name",
	FromName: "From name",
	FromEmail: "From email",
	Logo: "Logo",
	CurrentLogo: "Current Logo",
	Favicon: "Favicon",
	CurrentFavicon: "Current Favicon",
	Domains: "Subdomain",
	OneLastStep: "One Last Step",
	AddYourOwnDomain: "Add you subdomain for the web app",
	AddYourOwnDomainMobile: "Add you subdomain for the mobile app",
	AddYourOwnDomainDesc: " Example: Leads.yoursubdomain.com ", // starting with <b>“https://”</b> or <b>“http://”</b>
	AddYourOwnDomainDescMobile: " Example: Leads.yoursubdomain.com ", 
	Domain: "Subdomain",
	Pending: "Pending",
	Action: "Action",
	SaveChanges: "Save changes",
	AddNewMember: "Add new member",
	NoDataToDisplay: "No data to display",
	InProcess: "In Process",
	ResendInvitation: "Resend Invitation",
	CancelInvitation: "Cancel Invitation",
	EmailAddressRequired: "Email Address Required",
	WorkspaceDesc:
	"This person will receive an email request to accept your invitation",
	YouWantToRemove: "You want to remove",
	InvitationMemberDesc: "Do you really want to remove this invitation ?",
	PayAsYouGrow: "Pay as you grow",
	PayPlanDesc:
	"Our simple, transparent and pay as you go pricing helps solopreneurs,<br>agencies and businesses of all sizes to effectively manage their leads",
	MonthlyBilling: "Monthly billing",
	AnnualBilling: "Annual billing",

	CurrentPlan: "Current plan",
	Per: "Per",
	Confirm: "Confirm",
	ChangePlanTo: "Change Plan to :",
	QuestionContinue: "Are you sure you want to continue ?",

	LeadStatusDesc:
	"This is the filter tabs goes throught from start to finish. Stages automatically set the status of Lead based on their type.",
	StatusLabel: "Status label",
	IsQualified: "Is qualified",
	Default: "Default",
	SpecialCharacters: "Special characters &amp; space are not allowed",

	DeleteStatus: "Delete status",
	EditStatus: "Edit status",
	AddCustomStatus: "Add custom status",
	FacebookToken: "Facebook Token",
	Expired: "Expired",
	Regenerate: "Regenerate",

	PageName: "Page name",
	GeneratedBy: "Generated by",
	CreatedAt: "Created at",
	Actions: "Actions",
	WorkspaceName: "Workspace Name",

	TimezoneWorkspace: "The timezone to be used in this workspace.",
	CountryWorkspace:
	"Country field will help us determine the phone prefix, that will be used in all your calls.",

	// new -------------------
	Start: "Start",
	Edit: "Edit",
	View: "View",
	
	
	AdminDashboard: "Admin Dashboard",
	ErrorLoadUsers: "Error, unable to retrieve the list of users.",

	// Agency Deal -----------------

	AbsolutelyFreeToTry: "Absolutely FREE to try",
	Message1: "No catch. See it for yourself for 14 days absolutely free.",
	CancelOrDowngradeAnytime: "Cancel or Downgrade anytime",
	Message2:
	"You can upgrade, downgrade or cancel your subscription anytime. No questions asked.",
	SatisfiedOrMoneyBack: "Satisfied or Money Back",
	Message3:
	"Get your money back in less than 24h, after using our software for 30 days.",
	SignupAgency:
	"Signup for a Yearly Agency Plan and get the white label feature for free",
	LeadsFor: "Leads for",
	YearOnly: "Year only",
	YourFullName: "Your full name",
	SyncAndKeep: "Sync & Keep",
	GetStarted: "Get Started",
	Review1:
	"Handy for scaling lead gen processes. At the moment we do these things fairly manually however when I scale up this will help keep data in the right place",
	Review2:
	"In short, this is like air traffic control for your leads - it makes sure that no leads fall through the cracks and they get the most appropriate immediate response",
	Review3:
	"My sales reps can now dive into a lead without bouncing around, everything is synced inside the app, all incoming leads are qualified, all the details and lead nurturing process is updated in the CRM, dreamy.",

	// Free Register -----------------------
	YourEmailAddress: "Your email address",
	TypeYourPassword: "Type Your Password",
	WelcomeOnBoard: "Welcome on board !",

	//Login -----------------------------------
	YourWorkEmail: "Your work Email",
	WelcomeBack: "Welcome back !",
	Or: "OR",

	// Register ------------------------------
	YouInvited: "You've been invited",
	HasInvitedYou: "has invited you",
	ToJoin: "to join ",
	NameLenghtError: "The name field must be at least 3 characters.",
	EmailNotValid: "The email address is not valid.",
	PasswordLenghtError: "The password field must be at least 8 characters.",
	PhoneNotValid: "This phone number is not valid.",
	EmailAlreadyUsed: "This email address is already used",

	//TrialRegister -----------------------------------

	StartYourFreeTrial: "Start your FREE 7 days trial",
	YourTrialUntil:
	"Your 7-day trial starts today, is <b>100% free</b> and lasts until <b>{until}</b>. Cancel anytime.",
	YouHaveChosen: " You have chosen the",
	Plan: "Plan",
	WhatsIncluded: "What’s included",
	SoloFeatures:
	'<li><i class="fa fa-check" style="font-size: 14px;"></i> Unlimited facebook forms</li><li><i class="fa fa-check" style="font-size: 14px;"></i> Unlimited Custom Fields</li><li><i class="fa fa-check" style="font-size: 14px;"></i> Email & Phone Validation</li><li><i class="fa fa-check" style="font-size: 14px;"></i> Unlimited Lead Alert</li><li><i class="fa fa-check" style="font-size: 14px;"></i> Unlimited Welcome Email</li><li><i class="fa fa-check" style="font-size: 14px;"></i> Excel Import & export</li>',
	TeamFeatures:
	'<ul><li><i class="fa fa-check" style="font-size: 14px;"></i> Unlimited facebook forms</li><li><i class="fa fa-check" style="font-size: 14px;"></i> Unlimited Custom Fields</li><li><i class="fa fa-check" style="font-size: 14px;"></i> Email & Phone Validation</li><li><i class="fa fa-check" style="font-size: 14px;"></i> Unlimited Lead Alert</li><li><i class="fa fa-check" style="font-size: 14px;"></i> Unlimited Welcome Email</li></ul><ul><li><i class="fa fa-check" style="font-size: 14px;"></i> Excel Import & export</li><li><i class="fa fa-check" style="font-size: 14px;"></i> Unlimited users</li><li><i class="fa fa-check" style="font-size: 14px;"></i> Unlimited leads / day</li><li><i class="fa fa-check" style="font-size: 14px;"></i> Premium Support Package</li></ul>',
	AgencyFeatures:
	'<ul><li><i class="fa fa-check" style="font-size: 14px;"></i> Unlimited facebook forms</li><li><i class="fa fa-check" style="font-size: 14px;"></i> Unlimited Custom Fields</li><li><i class="fa fa-check" style="font-size: 14px;"></i> Email & Phone Validation</li><li><i class="fa fa-check" style="font-size: 14px;"></i> Unlimited Lead Alert</li><li><i class="fa fa-check" style="font-size: 14px;"></i> Unlimited Welcome Email</li><li><i class="fa fa-check" style="font-size: 14px;"></i> Excel Import & export</li><li><i class="fa fa-check" style="font-size: 14px;"></i> Unlimited users</li><li><i class="fa fa-check" style="font-size: 14px;"></i> Unlimited leads / day</li></ul><ul><li><i class="fa fa-check" style="font-size: 14px;"></i> Premium Support Package</li><li><i class="fa fa-check" style="font-size: 14px;"></i> White label</li><li><i class="fa fa-check" style="font-size: 14px;"></i> Unlimited workspaces</li><li><i class="fa fa-check" style="font-size: 14px;"></i> Custom Integrations</li><li><i class="fa fa-check" style="font-size: 14px;"></i> Coaching Onboarding Sessions</li><li><i class="fa fa-check" style="font-size: 14px;"></i> Dedicated Specialist</li><li><i class="fa fa-check" style="font-size: 14px;"></i> Periodic Account Review</li></ul>',
	DueToday: "Due Today",
	Then: "Then",
	After7Days: "After 7 Days",
	MoneyBackGuarantee: "100% Money Back Guarantee !",

	//Checkout ------------------------------------------------------------------
	SubscribeToSolo: "subscribe to solo",
	SubscribeToTeam: "subscribe to team",
	SubscribeToAgency: "subscribe to agency",

	// CRMs ----------------------------------------------------------------------

	CRMsSearch: "CRMs Search",
	
	//  ESP --------------------------------------------------------------------

	FindYourEsp: "Find your esp",

	// Lead Enrichement -------------------------------------------------------

	ConnectYourAccount: "Connect your { tools } account",
	YourAPIKey: "Your API key",
	PleaseFillField: "Please fill out this field.",
	APIKey: "API key",
	ConnectedSuccessfully: "Connected Successfully..",
	ApiKeyRequired: "Api Key Required !",
	Disconnected: "Disconnected..",

	// Voip ----------------------------------------
	
	// MyLeads ----------------------------------------------------------

	Filter: "Filter",
	Title: "Title",
	Statuses: "Statuses",
	Search: "Search",
	SendSMSViaVoip:
	"Send an SMS via your <strong>favorite Voip</strong> account to engage with your leads",
	WriteNoteLead: "Write a note about this lead...",
	EnterNameField: "Enter Name Field",
	EnterValueField: "Enter Value Field",
	GeneratedOn: "Generated On",
	Sources: "Sources",
	EmailValid: "Email Valid",
	PhoneValid: "Phone Valid",
	ChangesSaved: "Changes saved",
	FieldNoteRequired: "Field note required !",
	LeadHasBeenSent: "Lead has been sent ! ",
	LeadsHaveBeenSent: "Leads have been sent ! ",
	StatusChanged: "Status Changed !",
	LeadDeleted: "Lead Deleted !",
	DaysAgo: " Days Ago",
	Today: " Today",
	New: "New",
	MonthAgo: " Month Ago",
	MonthsAgo: " Months Ago",
	YearAgo: " Year Ago",
	YearsAgo: " Years Ago",
	FieldRequired: "Field required !",
	EmailOrPhoneValid: "Email or phone must be valid !",
	duplicate: "Duplicate",
	duplicates:"Duplicates",
	mark_as_a_duplicate:"Mark as a duplicate",
	lead_details:"Lead Details",
	send_email:"Send E-mail",
	send_sms:"Send SMS",
	next:"Next",
	previous:"Previous",
	email_lead_subject : "Subject",
	email_lead_to : "To",
	email_lead_from : "From",
	email_lead_template : "Template",
	email_lead_choose : "Choose",
	email_add_template : "Select",
	email_lead_discard: "Discard",
	email_lead_send : "Send",
	email_sent      : "Email Sent to the lead",
	title_required: "Title required",
	subject_required: "Subject required",
	body_required: "Body required",
	ConfrmDeleteLeads: "Do you really want to delete those leads ?",

	//CMTP
	provider_name_required:"Provider name required",
	provider_name:"Provider name",
	add_provider:"Add Provider",
	smtp_provider:"SMTP Provider",
	smtp_port_number:"SMTP Port Number",
	smtp_port_number_here:"SMTP port number here...",
	smtp_port_required:"SMTP port required",
	smtp_server:"SMTP Server",
	example:"For example: smtp.mysmtp.com",
	smtp_server_required: "SMTP server required",
	smtp_user_name_required: "User name required",
	smtp_user_name: "Username",
	smtp_email: "Email",
	smtp_email_required: "Email required",  
	need_help_to_find_SMTP_server_and_port_number: "Need help to find SMTP server and port number?",
	click_here_to_get_more_information:"Click here to get more information.",
	smtp_password: "Password",
	smtp_password_required: "Password required", 
	edit_provider: "Edit Provider",
	services_SMTP: "Services SMTP",
	smtp_update: "Update",
	smtp_cancel: "Cancel",
	smtp_edit: "Edit",
	smtp_delete: "Delete",
	smtp_provider_listing: "Provider",
	smtp_email_listing: "Email",
	smtp_status_listing: "Status",
	voulez_vous_vraiment_supprimer: "Are you sure you want to delete?",
	smtp_yes_confirmation:"Yes",
	smtp_save: "Save",
	add_smtp_provider:"Add SMTP Provider",
	update_smtp_provider:"Update SMTP Provider",


	// Qualification --------------------------------------------------

	HandleInbound:
	"Handle inbound/outbound phone calls, documenting and processing customer service requests, liaison between dispatch and field crews.",
	ClickToStart: "Click to start",
	YouHaveToQualify: "You have 04m : 47s to qualify this lead.",
	Score: "Score",
	InOrderToUse:
	"In order to use this feature, please connect your Favorite Voip account and start dialing right away!",
	CallUsingYour: "Call using your default App",
	Template: "Template",
	Choose: "Choose",
	Discard: "Discard",
	ConnectyourEnrichmentProvider: "Connect your Enrichment Provider",
	IntegrationMessage:
	"This integration helps you transform partial identities into complete profiles with infos such as its job, LinkedIn and Twitter profiles, location, age, sex, interests etc.",
	LeadEnrichmentIntegrations: "Lead Enrichment Integrations",
	SendYourSMSViaVoip:
	"Send an SMS via your <strong>favorite Voip</strong> account to engage your lead",

	// Facebook LeadAds

	Page: "Page",
	SelectYourFacebookLeadAdForm: "Select your facebook lead ad form",
	LeadSourceName: "Lead source will have the same name",
	PleasProvideNameSource: "Please provide a name to your source.",
	PageNoLeadAd:
	"The page doesn't have any lead ad. Please choose an other page or Create the ad first.",
	NoLeadSoFar: "( No lead so far )",
	Welcome: "Welcome",

	// Gravity Forms
	ConnectYourGravityForms: "Connect your Gravity Forms",
	BeforeStarting:
	"Before starting to use your Gravity Forms. You need to link our homemade plugin with your WordPress website. You have a few",
	Steps: "steps",
	OurPlugin: "to accomplish to make it happen. Start by downloading our plugin",
	DownloadOurPlugin: "Download our plugin",
	RememberActivate:
	"Please remember to install Ohmylead plugin",
	SaveAndContinue: "Save & Continue",
	WebhookLink: "Webhook Link",
	CopyLink: "Copy Link !",
	Instructions: "Instructions",
	GravityInstructions:
	'<p>Once you connect your <strong>Gravity Forms</strong> to <strong>Ohmylead</strong>, Head to the Gravity Forms Dashboard and select Forms.<br>On the forms list, select Form Settings and finally click on <strong>Ohmylead</strong><br><br><img src="/images/Integration/sources/gravityFormStep/step1.png" alt="" class="doc-img"/></p><p>Once on the Ohmylead Feeds page, click on <strong>Add New</strong><br><br><img src="/images/Integration/sources/gravityFormStep/step2.png" alt="" class="doc-img"/></p><p>Fill the mapping section & match the fields to proceed the next section.<br><br><img src="/images/Integration/sources/gravityFormStep/step3.png" alt="" class="doc-img"/></p><p>Update settings and start Qualifing your Leads!<br><br><img src="/images/Integration/sources/gravityFormStep/step4.png" alt="" class="doc-img"/></p>',
	ReturnToLeadList: "Return to lead sources list",
	DownloadPlugin: "Download plugin",
	WebhookLinkInstructions: "Webhook Link & Instructions",
	WebhookSuccessfullyAdded: "Webhook successfully added",
	instructionWebhook : '<div><b>Use the URL above to create a webhook integration on your favorite lead generation tool, then, map the fields of your lead generation form to the following OhMyLead fields:</b></div><div>first_name (optional)</div><div>last_name (optional)</div><div>full_name (optional)</div><div>email (optional)</div><div class="mb-3">phone (optional)</div><div class="mb-3">Any additional fields will be saved to Ohmylead as custom fields.</div><div><b>The default OhMylead fields are:</b></div><div>first_name Map if with the first name if exists</div><div>last_name Map if with the last name if exists</div><div>full_name Map if with the full name if exists</div><div>email Map if with the email</div><div>phone Map if with the phone number if exists</div><div>You can choose any additional fields, they will be added in Ohmylead as custom fields</div><div class="mb-3">Keep the remaining options as default.</div>',

	// Export EXCEL

	CustomField: "Custom field",
	MapYourFields: "Map your fields !",

	// Web Hook API
	CreateYourSource: "Create your source",

	//New Source
	ConnectYourLeadSource: "Connect your lead source",

	//ClietSettings
	YourWorkspaceEdited: "Your workspace has been edited successfully",

	//Dispatch
	DispatchSettings: "Dispatch Settings",
	ChooseTheStatus: "Choose the status to exclude from dispatch",
	ChooseExclude: "Choose the sources to exclude from dispatch",
	PickSomeStatus: "Pick Some Status",
	LeadLockTime: "LeadLockTime",
	MaxNumber: "Max Number of recall if unreachable",
	IfUnreachable:
	"if unreachable,automatically schedule next call after (minute)",
	DispatchCallBacksTo: "Dispatch call-backs to",
	SameAgent: "Same agent",
	Anyone: "Anyone",
	WichLeads: "Which leads to call first",
	CallOldestFirst: "Call oldest first",
	CallNewstFirst: "Call newst first",

	//Email Templates
	MyTemplates: "My templates",
	AddTemplate: "Add template",
	TypeToSearch: "Type something to search",
	TypeTemplateName: "Type your Template Name",
	TheReason: "Write the email reason",
	Body: "Body",
	YouWantToDeleteTemplate: "You want to delete your template :",
	search_here:"Search...",
	email_templates:"Email Templates",
	add_email_template: "Add Email Template",
	title : "Title",
	type_your_template_title_here : "Type your template title here ...",
	write_the_email_reason_here:"write the email reason here ...",
	subject : 'Subject',
	body    : "Body",
	insert_text_here  : "Insert text here ...",
	template_cancel  : "Cancel",
	template_save : "Save",
	SMS_Email_Templates : "Email Templates",
	template_delete : "Delete",
	template_edit : "Edit",
	template_update : "Save",
	nothing_here_yet : "Nothing here yet !",
	Nothing_smtp_yet: 'Nothing here yet !',
	you_can_create_an_email_template_by_clicking_on_this_button : "You can create an email template by clicking on this button",
	new_email_template : "New email template",
	template : "Template",

	//List Client
	ManageWorkspaces: "Manage Workspaces",
	Workspaces: "Workspaces",
	DoYouWantToDeleteWorkspace: "Do you really want to delete this workspace ?",

	//Profile
	WhyYouDecidedToCancel:
	"We're sorry to hear that you want to leave us, and we'd love to know if there's anything we can do to continue working with you! ",
	YourResponses:
	"Your responses will be kept confidential and will not be used for any purpose other than research conducted by our company.",
	HowLongService: "How long have you been using Ohmylead ?",
	JustStarted: "Just started",
	UpToSixMonths: "Up to 6 months",
	UpToYear: "Up to 1 year",
	Overall: "Overall, how satisfied were you with our subscription / service?",
	VerySatisfied: "Very Satisfied",
	Satisfied: "Satisfied",
	Neutral: "Neutral",
	Unsatisfied: "Unsatisfied",
	WhatAreTheReasons:
	"What was the primary reason for canceling your subscription / service?",
	NoLongerNeededIt: "No longer needed it",
	ItDidNotMeetMyNeeds: "It didn't meet my needs",
	FoundAlternative: "Found an alternative",
	QualityLessThanExpected: "Quality was less than expected",
	EaseLessThanExpected: "Ease of use was less than expected",
	AccessLessExpected: "Access to the service was less than expected",
	CustomerServiceLessThanExpected: "Customer service was less than expected",
	Other: "Other",
	IfOther: "If other, please specify",
	WhatCouldWeDo: "What could we do to improve our subscription / service?",
	Submit: "Submit",

	//Scoring
	WhatIsScoring: "What is lead scoring?",
	ScoringHtml:
	"<p>Lead scoring allows you to determine the worthiness of your leads, by attaching values to them based on how the company perceives their behavior. Hence, this will rank your leads accordingly, qualifying the best leads.</p><p>Lead scoring has basically a point based scoring system that categorizes your leads in three categories. You can set up your lead score by adding different values to conform with your companies goals, such as, marketing source, country, industry, lead status and more.</p>",
	ScoreFor: "Score for a valid email & phone address",
	AddStatus: "Add status",
	SelectStatus: "Select Status...",
	YourStatus: "Your Status",
	YourSources: "Your Sources",
	AddSource: "Add Source",

	//404
	LookslikekeYourLost: "Looks like you're lost",
	PageNotExist: "the page you are looking for does not exist!",
	GoBack: "Go back",

	//Pricig
	Pricing: "Pricing",
	PricingDesc:
	"Our simple, transparent and pay as you go pricing helps solopreneurs, agencies and businesses of all sizes to effectively manage their leads",
	YearlyBilling: "Yearly Billing (3 months FREE)",
	SignUpForFree: "Sign up for free",
	ContactUs: "Contact Us",
	SignUp: "Sign up",

	// Menu component
	Dashboard: "Dashboard",
	Register: "Register",

	// drop options
	NoItems: "No items to display",

	// Lead alerts tabs
	NotificationSubject: "Notification subject",
	TypeRecipientEmail: "Type recipient email",
	EmailAddressWillReplyTo: "Email address will reply to",
	TheSenderEmail: "The sender email",
	AvailableTags: "Available tags",

	//Welcome EmailTabs

	InsertYourMessage: "Insert Your Message ...",

	Search2: "Search ...",

	//Reset
	ConfirmPassword: "Confirm Password",

	//new 12/20/2020
	//Plugin datatable - myleads
	NoLeadToDisplay: "No lead to display ! ",
	verificationInProgress: "verification in progress",
	Copy: "Copy",
	Call: "Call",
	WhatIsLeadScoring: "What is lead scoring ?",
	leadScoringMessage1:
	"A point based scoring system that will let you determine which leads should be qualified in order of priority",
	leadScoringMessage2: "You can configure your scoring system by",
	clickingHere: "clicking Here",
	UPGRADENOW: "UPGRADE NOW",

	// Plugin datatable clients
	Leaveworkspace: "Leave Workspace",
	Nodatatodisplay: "No data To display",

	MappingDesc: "Map and match your fields with the qualification interface",
	AlertDesc: "Don't miss any new leads with those smart notifications",
	welcomemailDesc: "Engage your leads instantly by sending a welcome mail",

	FacebookTokenDesc:
	"A token is a secret code used to authenticate on Facebook. You can see and manage your Facebook tokens here",
	SourceName: "Source name",
	Yourpayementwassucceful: "Your payement was succeful",
	Yourpayementwasnotsucceful: "There is an error in your payment process",
	
	// new -------------------
	Success				: 'Success',
	Checking			: 'Checking',
	Reconfig			: 'Reconfig',
	
	Integration			: 'Destination',
	CRMs				: 'CRMs',
	LeadEnrichment		: 'Lead Enrichment',
	ESP					: 'ESP',
	VOIP				: 'VoIP',
	
	Integrations		: 'Destinations',
	IntegrationsDesc	: 'Destinations are where you send your leads after they are processed. For example, you can send hot leads to Hubspot CRM and cold leads to Mailchimp.',
	ToolSearch			: 'Tool Search',
	InInfoDesc			: 'Can’t find the destination you want?',
	LetUsknow			: 'Let us know here',
	LEPSearch			: 'Find your Lead Enrichment Tool...',
	EspPSearch			: 'Find your ESP...',
	VoipPSearch			: 'Find your VoIP...',
	CrmPSearch			: 'Find your CRM...',
	
	
	'Select Form'		: 'Select Form',
	'Import File'		: 'Import File',
	'Mapping Fields'	: 'Mapping Fields',
	
	PopupDTitle		: 'Hi there!',
	PopupDMessage	: 'Let me show you how to manage your leads',
	PopupDButton	: 'Start with demo data',
	
	ConfigWLDesc	: 'To change this subdomain, click on the delete button above to delete this configuration and create a new one.',
	SaveDomain		: 'Save',
	IveDoneThisStep	: 'I’ve done this step',
	CustomizeLogo	: 'Customize logo',
	CustomizeEmail	: 'Customize email',
	
	H5ModalWL		: 'Your white label configuration is saved',
	SmallModalWL	: 'We advise previewing your white label configuration before sharing Ohmylead with your clients. Get in touch with support if you need help',
	TakeALookWL		: 'Preview your white label',
	DomainVerfWL	: 'Subdomain name verification will take a little while',
	DoNotPanicWL	: '',

	//added 6 Dec
	WLDesc			: 'OML Agency Plan allows you to offer Ohmylead platform to your customers under your own brand. Here you can customize the subdomain (also called URL or web address) your logo and email notification sender.',
	WLLogoDesc		: 'Choose a png or jpg file size 300x100 or larger.',
	WLFavIconDesc	: 'Choose a png or jpg file size 50x50.',
	WLEmailDesc		: 'Ohmylead allows sending email notifications when new leads are received. Customize here the sender name and address of these notification emails.',

	AnalyticsFunnel	: 'Your leads processing funnel in three stages: 1. total number of leads 2. processed leads are those with at least one status changed 3. qualified leads are those with status qualified (see leads status settings)',

	iProcessingRate : 'Leads with at least one status change / total leads',
	iQualificationRate : 'Leads with status qualified / total leads',
	iShowStatus		: 'Show or hide this status option in leads detail view',
	iIsQualified	: 'Count this status as qualified in statistics',
	iDefault		: 'Selected status will be shown by default in My Leads filter',
	Awsome			: 'Awesome',
	SorryQuota		: 'Sorry, you have exceeded your quota for the number of leads',
	Details : 'Details',
	SelectAll : 'Select All',
	Id : 'Id',
	ProcessingRate : 'Processing Rate',
	QualificationRate : 'Qualification Rate',

	//analytics
	Overview : "Overview",
	TopSources : "Top Sources",
	topFbC : 'Top Fb Campaigns',
	QualifiedLeads :'Qualified Leads',
	Campaign : 'Campaign',
	Adset : 'Adset',
	Ads : 'Ads',


	AcceptInvitation : 'Accept Invitation',
	IgnoreInvitation : 'Ignore Invitation',
	SendInvitation :'Send Invitation',
	InviteNewTeamMember : 'Invite a new team member',

	//roles
	hasFull : 'You have full access to view and you can edit all account information including leads, sources and settings',
	hasView : 'Has view-only access to all account information',
	hasFullTwo : 'You have full access to view and you can edit all account information.',
	Invited : 'Invited',
	ProcessedLeads : 'Processed Leads',
	ConnectSource : 'Connect your lead source',
	TypTtheNewPassword : 'Type your new password',
	sendMessage :'Send Message',
	sendEmail :'Send E-mail',
	Language : 'Language',

	Yoursourcenamehere : 'Your source name here ...',

	//Webhook

	Copylink : "Copy link",
	Method : "Method",
	Link : "Link",
	Input : "Input",
	InputMapping : "<div><b>The default OhMylead fields are:</b></div><div>first_name Map if with the first name if exists</div><div>last_name Map if with the last name if exists</div><div>full_name Map if with the full name if exists</div><div>email Map if with the email</div><div>phone Map if with the phone number if exists</div><div>You can choose any additional fields, they will be added in Ohmylead as custom fields</div><div class='mb-3'>Keep the remaining options as default.</div>",


	engageYourLead : 'Engage your leads instantly by sending a welcome mail',
	Enabled : 'Enabled',
	Disabled : 'Disabled',
	NoCardNeeded : 'No credit card needed.',
	Processed : 'Processed',
	Qualified : 'Qualified',
	Alla : 'All ',
	thisIsTheLink : 'Here is the invitation link to your workspace',
	linkDesc : 'Or copy this link and send it to the person you want to invite.',
	copyButton : 'Copy the link ',
	InvitationLink : 'Invitation Link',
	ThisMemberCanAccessTo : 'This member will have access to',
	hasCustom :'Custom permissions let you define all access checks that can be assigned to members.',
	Editor : 'Editor',
	Viewer : 'Viewer',
	Admin : 'Admin',
	Custom : 'Custom',
	editor : 'Editor',
	viewer : 'Viewer',
	admin : 'Admin',
	custom : 'Custom',
	account_owner : 'Account owner',
	Noaccess : 'You don\'t have the necessary permissions to access this page',

	// abdeALKARIM 
	/*
	|--------------------------------------------------------------------------
	| date ranger
	|--------------------------------------------------------------------------
	*/
	apply : 'Apply',
	cancel : 'Cancel',
	today : 'Today',
	yesterday : 'Yesterday',
	thismonth : 'This month',
	thisyear : 'This year',
	lastmonth : 'Last month',

	sun : 'Sun',
	mon : 'Mon',
	tue : 'Tue',
	wed : 'Wed',
	thu : 'Thu',
	fri : 'Fri',
	sat : 'Sat',

	w : 'W',

	jan : 'Jan',
	feb : 'Feb',
	mar : 'Mar',
	apr : 'Apr',
	may : 'May',
	jun : 'Jun',
	jul : 'Jul',
	aug : 'Aug',
	sep : 'Sep',
	oct : 'Oct',
	nov : 'Nov',
	dec : 'Dec',

	/*
	|--------------------------------------------------------------------------
	| Webhook destination
	|--------------------------------------------------------------------------
	*/
	Connect_your_destination : 'Connect your destination',
	Create_new_destination : 'Create new destination',
	your_web_hook_destination_updated_successfully : 'Your webhook destination have been updated successfully',
	your_web_hook_destination_created_successfully : 'Your webhook destination have been created successfully',
	Request_parameters : 'Request parameters',
	Sources_where_to_apply : 'Sources',
	When_lead_status_changed_to : 'Trigger when lead status changes to',
	Request_Query : 'Request parameters',
	Request_headers : 'Request headers',
	Request_Method : 'Request method',
	Request_URL : 'URL',
	Name_of_destination : 'Name',
	Active : 'Active',
	Destination_via_Webhook : 'Webhook',
	New_Destinations : 'New destination',
	Map_the_landing_form_fields_with_the_fields_in_our_server : 'Mapping',
	Pick_statuses : 'Pick statuses',
	Pick_sources : 'Pick sources',
	Your_name_of_destination_here : 'Destination\'s name',
	Your_endPoint_of_destination_here : 'Destination\'s URL',
	My_destinations : 'My destinations',
	destinations : 'Destinations',
	Type : 'Type',
	Created_at : 'Last updated on',
	The_name_is_required : 'The name is required',
	Enter_a_valid_url_endpoint_for_your_destination : 'Enter a valid url endpoint for your destination',
	Choose_a_request_method : 'Choose a request method',
	Choose_at_least_1_source : 'Choose at least 1 source',
	Choose_at_least_1_status : 'Choose at least 1 status',
	Key : 'Key',
	Value : 'Value',
	Cant_find_the_integration_you_want: 'Can’t find the integration you want',
	Let_us_know_here: 'Let us know here',
	Nothing_here_yet: 'Nothing here yet',
	
	desc_workspace: "You can manage multiple clients within the same account, with a total separation of leads, lead sources and team members.",
	desc_leadStatus: "Lead Status represent a Lead's current relationship to your company. You will notice that we have pre-loaded some commonly-used Lead Statuses into your Ohmylead account as examples. These can be changed to reflect your team's specific lead management process.",
	desc_member: "You can add users simply by sending them an invitation then select the role you'd like to assign.",
	desc_emailTemplate: "If you find y ourself sending similar emails over and over, you may want to consider using the Email Templates feature.",
	desc_smtp: "Set up an SMTP email sending service to maximize your email deliverability (if you don’t yet have an SMTP service with credentials). Any SMTP email service can be used.",

	Nothing_template_yet: "Nothing here yet !",

	/*
	|--------------------------------------------------------------------------
	| Leads details
	|--------------------------------------------------------------------------
	*/
	sendEmail_config_msg: "Set up an SMTP email sending service to maximize your email deliverability.",
	config_from_here: "Click here.",
	msgCreateWorkspace: "Create a workspace to use this functionality",
	create: "CREATE",




	RETRY:'RETRY',
	NAME:'NAME',
	AssignedTo:'Assigned to',
	Members2:'Members',
	Assign:'Assign',
	SaveView:'Save view',
	calendlyNoResult:'Schedule One-on-One and Group events right through Ohmylead',
	EmailSentTo:'Email sent to',
	BodyHere:'Body here',
	ShowMore:'Show more',
	please_confirm:'Please Confirm',
	SendTo:'Send to',

	SMART_VIEWS:'SMART VIEWS' ,
	Unassigned:'Unassigned',
	copied:'Copied',
	cantCopy:'Can not copy..',

	Add_view:'Create view',
	edit_view:'Edit view',

	fieldNotRequired:'Field Note Required !',
	statusChanged:'Your lead status has been changed',
	leadDeleted:'Your lead has been deleted',
	leadsDeleted:'Your leads have been deleted',
	fieldRequired:'Field required !',
	dataNotFound:'Data not found !',
	emailPhoneMustValid:'Your Email or phone must be valid !',
	mustEnterName:'You must enter a name',
	leadSentToWorkspace:'Your lead has been sent to your workspace',
	mustChooseMember:'You must choose a member',
	leadAssignedToMember:'Your lead has been assigned',
	chooseWorkspace:'Choose a workspace',
	chooseMember:'Choose a member',

	leadHasBeenSent:'Your Lead has been sent',
	leadsHaveBeenSent:'Your Leads have been sent',
	yourSmartViewSent:'Your smart view has been created',

	emailSentLead:'Your email has been sent',
	noteAdded:'Your note has been added ',
	LeadAccess:'Access to leads',




	emailFormRequired:'Email form required',
	domainNameRequired:'Domain name required !',
	mustChooseLogoFavicon:'You must choose a logo and favicon',
	Owner:'Owner',
	youCanConfigCalendly:'Here you can configure your Calendly.',
	calendlyKey:'Calendly key ...',
	getYourApiKey:'Get your Calendly API key here',
	
	newOML : 'Boarding time, get started with Ohmylead',
	singleSource : 'Before going any further, we would recommend you check out our walkthrough video.',
	stillHaveQuestion : 'Still have questions about Oh My Lead ?',
	bookFree : 'Book a FREE live demo!',
	now_lead: 'Now',

};
export default Eng;
